// import {
//   //  fetchTransactions, fetchTransactionsUsingWalletId, fetchTransactionsUsingUserId, 
//   fetchUser } 
//   from "@/services/api";
// import Button from "../../UI/Button";
// import Loader from "../../Loader";
// import { useQuery } from "@tanstack/react-query";
// import { useNavigate } from "react-router-dom";
// import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
// import { ITransaciton } from "@/types/transactions";
// import { useNetwork } from "@/provider/NetworkContext";
// import useAuth from "@/hooks/useAuth";
import Button from "@/components/UI/Button";
import TransactionItem from "./TransactionItem";
import { useNavigate } from "react-router-dom";

// import TransactionItem from "../Transactions/TransactionItem";

// interface ITransactionsProps {
//   selectedTransaction: ITransaciton | null;
//   setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
// }

// const RecentTransactions = ({ userData, selectedTransaction, setSelectedTransaction }: ITransactionsProps) => {
//   const navigate = useNavigate();
//   console.log("User Data in TX: ",userData)
//   // const { selectedNetwork } = useNetwork();
//   // const { userId, investorId } = useAuth();
//   // const [allTransactions, setAllTransactions] = useState<ITransaciton[]>([]);

//   // const {
//   //   data: userData,
//   //   isLoading: isLoadingUser,
//   //   refetch,
//   // } = useQuery({
//   //   queryKey: ["getUser", userId],
//   //   queryFn: () => fetchUser(userId),
//   //   enabled: !!userId,
//   //   select: (data) => data.data,
//   // });

//   // const transactions = null,
//   // const {
//   //   data: transactions = [],
//   //   isLoading: isLoadingTransactions,
//   //   error: transactionsError,
//   // } = useQuery({
//   //   queryKey: ["getTransactions", investorId],
//   //   queryFn: () => fetchTransactions(investorId, selectedNetwork),
//   //   enabled: !!investorId,
//   //   select: (data) => data.data ?? [],
//   // });

//   // transactionsUsingWalletId = null,
//   // const {
//   //   data: transactionsUsingWalletId = null,
//   //   isLoading: isLoadingTransactionsUsingWalletId,
//   //   error: transactionsError2,
//   // } = useQuery({
//   //   queryKey: ["getTransactionsUsingWalletId", userData?.walletDetails?.web3authWalletAddress],
//   //   queryFn: () => fetchTransactionsUsingWalletId(userData?.walletDetails?.web3authWalletAddress),
//   //   enabled: !!userData,
//   //   select: (data) => data?.data ?? null,
//   // });

//   // transactionsUsingUserId = null,
//   // const {
//   //   data: transactionsUsingUserId = null,
//   //   isLoading: isLoadingTransactionsUsingUserId,
//   //   error: transactionsError3,
//   // } = useQuery({
//   //   queryKey: ["getTransactionsUsingUserId", userId],
//   //   queryFn: () => fetchTransactionsUsingUserId(userId),
//   //   enabled: !!userId,
//   //   select: (data) => data?.data ?? null,
//   // });

//   // const isLoading = isLoadingUser 
//   // || isLoadingTransactions || isLoadingTransactionsUsingWalletId || isLoadingTransactionsUsingUserId;

//   // useEffect(() => {
//   //   const combinedTransactions = [
//   //     ...(transactions || []),
//   //     ...(transactionsUsingWalletId?.transactions || []),
//   //     ...(transactionsUsingUserId?.transactions || []),
//   //   ].filter(Boolean); // Filter out any undefined/null values
//   //   setAllTransactions(combinedTransactions);
//   // }, [transactions, transactionsUsingWalletId, transactionsUsingUserId]);

//   // Compute and limit to the 5 most recent transactions
//   // const recentTransactions = useMemo(() => {
//   //   if (!allTransactions?.length) return [];
//   //   return allTransactions
//   //     .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
//   //     .slice(0, 5);
//   // }, [allTransactions]);
//   // const recentTransactions = null;

//   const navigateToAllTransactions = () => {
//     navigate(`/transactions`);
//   };

//   return (
//     <div className="bg-monochrome-100 p-4 flex flex-col rounded-lg">
//       <div className="flex justify-between items-center">
//         <h5 className="font-medium text-monochrome-20">Recent Transactions</h5>
//         <Button disabled classNames="text-green-500 justify-end pr-0 text-[0.75rem] font-normal" onClick={navigateToAllTransactions}>
//           View All
//         </Button>
//       </div>
//       <div className="overflow-x-auto sm:overflow-hidden">
//         <div className="mt-4 w-[200%] sm:w-full">
//           <div className="grid grid-cols-5 text-monochrome-20 pb-6 pt-2 border-b border-monochrome-40">
//             <span className="text-[0.688rem] text-[#9E9E9E]">Type</span>
//             <span className="text-end text-[0.688rem] text-[#9E9E9E]">Symbols</span>
//             <span className="text-end text-[0.688rem] text-[#9E9E9E]">Date</span>
//             <span className="text-end text-[0.688rem] text-[#9E9E9E]">Time</span>
//             <span className="text-end text-[0.688rem] text-[#9E9E9E]">Amount</span>
//           </div>
//           {/* {isLoading ? (
//             <div className="flex justify-center items-center min-h-[200px]">
//               <Loader isLoading={true} />
//             </div>
//           ) : recentTransactions.length > 0 ? (
//             recentTransactions.map((transaction) => (
//               <TransactionItem
//                 key={transaction?._id}
//                 item={transaction}
//                 onClick={() => setSelectedTransaction(transaction)}
//                 isSelected={selectedTransaction?._id === transaction?._id}
//                 uniqueId={`transaction-${transaction?._id}`}
//                 currentUserId={userId}
//                 currentWalletAddress={userData?.walletDetails?.web3authWalletAddress || ''}
//               />
//             ))
//           ) : ( */}
//             <div className="center min-h-40 text-[0.75rem] text-monochrome-20">
//               No Recent Transactions.
//             </div>
//           {/* ) */}
//           {/* } */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RecentTransactions;


// RecentTransactions.tsx
interface UserData {
  prnmoTransactions: Array<{
    allocation: number;
    timestamp: string;
    txHash: string;
    _id: string;
  }>;
}

interface ITransactionsProps {
  userData: UserData;
}

const RecentTransactions: React.FC<ITransactionsProps> = ({ userData }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-monochrome-100 p-4 flex flex-col rounded-lg">
      <div className="flex justify-between items-center">
        <h5 className="font-medium text-white">Recent Transactions</h5>
        <Button classNames="text-green-500 justify-end text-[0.75rem] font-normal" onClick={() => navigate("/transactions")}>
          View All
        </Button>
      </div>
      <div className="overflow-x-auto sm:overflow-hidden">
        <div className="mt-4 w-full">
          <div className="grid grid-cols-4 text-monochrome-20 pb-6 pt-2 border-b border-monochrome-40">
            <span className="text-[0.688rem] text-[#9E9E9E]">Type</span>
            <span className="text-end text-[0.688rem] text-[#9E9E9E]">Symbol</span>
            <span className="text-end text-[0.688rem] text-[#9E9E9E]">Amount</span>
            <span className="text-end text-[0.688rem] text-[#9E9E9E]">Value</span>
          </div>
          
          {userData?.prnmoTransactions?.length > 0 ? (
            userData.prnmoTransactions.map((transaction) => (
              <TransactionItem
                key={transaction._id}
                transaction={transaction}
              />
            ))
          ) : (
            <div className="center min-h-40 text-[0.75rem] text-monochrome-20">
              No Recent Transactions.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentTransactions;