import IcPortfolio from "@/assets/ic_portfolio";
import { ROUTES } from ".";
import {
  IcDashboard,
  IcBuyPresaleToken,
  IcContactSupport,
  IcMarketplace,
  IcSetting,
  IcStakingCampain,
  IcTransactions,
  FillDashboard,
  FillMarketplace,
  FillStakingCampaign,
  FillTransaction,
  FillContactSupport,
  FillSetting,
  IcInvest,
  IcStaking,
  FillStaking,
  IcGovernance,
  FillBuyPresaleToken,
  FillInvest,
  FillGovernance,
  FillPortfolio,
} from "../assets/menu";
import Portfolio from "@/assets/portfolio.svg";

/**
 * The list of menu items to be shown in the sidebar
 */
export const NavBarList = [
  {
    title: "Dashboard",
    icon: <IcDashboard />,
    iconWhenSelected: <FillDashboard />,
    link: ROUTES.DASHBOARD,
    // customTextClass: "text-[#00CC9C] font-bold", // Green text for Dashboard

  },
  {
    title: "PNMO Presale (Round 2)",
    icon: <IcBuyPresaleToken />,
    iconWhenSelected: <FillBuyPresaleToken />,
    link: "/presale",
    // customTextClass: "text-[#00CC9C] font-bold", // Green text for Dashboard

  },
  {
    title: "Invest",
    icon: <IcInvest />,
    iconWhenSelected: <FillInvest />,
    link: ROUTES.MARKETPLACE,
  },
  {
    title: "Campaigns",
    icon: <IcStakingCampain />,
    iconWhenSelected: <FillStakingCampaign />,
    link: ROUTES.CAMPAIGN,
  },
  {
    title: "Portfolio",
    icon: <IcPortfolio />,
    iconWhenSelected: <FillPortfolio />,
    link: ROUTES.PORTFOLIO,
  },
  {
    title: "Transactions",
    icon: <IcTransactions />,
    iconWhenSelected: <FillTransaction />,
    link: ROUTES.TRANSACTIONS,
  },
  {
    title: "Staking",
    icon: <IcStaking />,
    iconWhenSelected: <FillStaking />,
    link: ROUTES.STAKING,
  },
  {
    title: "Governance",
    icon: <IcGovernance />,
    iconWhenSelected: <FillGovernance />,
    link: ROUTES.GOVERNANCE,
  },
  // {
  //   title: "Contact Support",
  //   icon: <IcContactSupport />,
  //   iconWhenSelected: <FillContactSupport />,
  //   link: ROUTES.CONTACTSUPPORT,
  // },
  {
    title: "Settings",
    icon: <IcSetting />,
    iconWhenSelected: <FillSetting />,
    link: ROUTES.SETTINGS,
  },
];
