import React, { createContext, useState, useContext, useCallback } from 'react';

// Create a global context for managing global state
const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [reFetch, setRefetch] = useState(false);

  // Toggle function for reFetch state
  const toggleRefetch = useCallback(() => {
    setRefetch((prev) => !prev);
  }, []);

  return (
    <GlobalContext.Provider value={{ reFetch, toggleRefetch }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use the global context
export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
