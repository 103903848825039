import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useAuth from "@/hooks/useAuth";
import { classNames } from "@/utils";
import { ROUTES } from "@constants";
import useStore from "@store/useStore";
import Logout from "./Logout";
import logo from "../assets/penomo_logo_white.svg";
import "./NavBar.css";
import { NavBarList } from "@/constants/NavMenu";
import { IcClose, IcHamburger } from "@/assets/menu";
import ContactSupportSucessModal from "./Page/ContactSupport/SucessModal";

const iconStyles = {
  white: { filter: "brightness(0) invert(1)" }, // Makes icon white
  green: { filter: "invert(55%) sepia(93%) saturate(385%) hue-rotate(116deg) brightness(97%) contrast(101%)" }, // Makes icon green (#00CC9C)
};

const NavBar = ({ handleLinkClick }: any) => {
  const { userData, investorData } = useStore();
  const { pathname } = useLocation();
  const { userId } = useAuth();
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navButtons = document.querySelectorAll(".nav-button");
  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
  const navigate = useNavigate();

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const isHighlightedByDefault = (title: string) => {
    return title === "Dashboard" || title === "PNMO Presale (Round 2)";
  };

  // Updated renderIcon function to handle Dashboard and PNMO Presale correctly
  const renderIcon = (item: any, isActive: boolean) => {
    if (item.title === "Dashboard" || item.title === "PNMO Presale (Round 2)") {
      // Use original icon coloring for Dashboard
      return isActive ? item.iconWhenSelected : <span style={iconStyles.white}>{item.icon}</span>;
    }

    if (item.title === "PNMO Presale (Round 2)") {
      if (isActive) {
        return <span style={iconStyles.green}>{item.iconWhenSelected}</span>;
      }
      return <span style={iconStyles.white}>{item.icon}</span>;
    }

    return isActive ? item.iconWhenSelected : item.icon;
  };

  const getTextStyle = (title: string, isActive: boolean) => {
    if (isHighlightedByDefault(title)) {
      return isActive ? "text-[#00CC9C] font-bold" : "text-white font-bold";
    }
    return isActive ? "text-[#00CC9C]" : "text-gray-400";
  };

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isNavOpen]);

  const handleCloseContactModal = (isSuccess: boolean) => {
    setShowSuccessDialog(isSuccess);
    setShowContactModal(false);
    navigate(ROUTES.DASHBOARD);
  };

  const handleCloseMobileMenu = (item: any = null) => {
    // if (item?.link === ROUTES.CONTACTSUPPORT) {
    //   setShowContactModal(true);
    // } else {
    //   navButtons.forEach((button) => button.classList.remove("active"));

    //   if (window.matchMedia("screen and (max-width: 1024px)").matches) {
    //     setIsNavOpen(!isNavOpen);
    //   }
    // }
    navButtons.forEach((button) => button.classList.remove("active"));

    if (window.matchMedia("screen and (max-width: 1024px)").matches) {
      setIsNavOpen(!isNavOpen);
    }
  };

  const isCampaignsVisible = investorData?.investorType !== "Accredited" && investorData?.investorType !== "Institutional";
  const isPNMOPreSaleVisible = investorData?.investorType !== "Accredited" && investorData?.investorType !== "Institutional";

  const filteredNavBarList = NavBarList.filter((item) => {
    if (item.title === "Campaigns" && !isCampaignsVisible) return false;
    if (item.title === "PNMO Presale" && !isPNMOPreSaleVisible) return false;
    return true;
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (userId && isSetupProfilePage) {
    return null;
  }

  return (
    <>
      <nav
        className={classNames(`lg:fixed lg:h-full w-full lg:w-72 bg-monochrome-100 ${isCampaignsVisible ? "border-r border-green-30" : ""}`, {
          hidden: !userId,
        })}
      >
        {isNavOpen && <div className="fixed inset-0 bg-monochrome-100 z-40 lg:hidden" />}

        <div
          className={`${windowHeight < 725 ? "lg:overflow-y-scroll" : "lg:overflow-y-none"} h-full flex flex-col justify-between px-4 lg:pl-8 lg:pr-0 lg:py-5`}
        >
          <div className="w-full grow">
            <div className="h-[88px] w-full flex justify-between items-center lg:items-start relative z-50">
              <NavLink to={ROUTES.DASHBOARD} className="flex pr-2">
                <img src={logo} className="navbar-logo h-8" alt="penomo" />
              </NavLink>
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="inline-flex items-center justify-center text-sm text-gray-500 rounded-lg lg:hidden"
                aria-controls="navbar-default"
                aria-expanded="false"
                onClick={() => handleCloseMobileMenu()}
              >
                {isNavOpen ? <IcClose /> : <IcHamburger />}
              </button>
            </div>

            <div
              className={classNames("lg:block", isNavOpen ? "fixed inset-0 pt-[88px] bg-monochrome-100 z-40 overflow-y-auto" : "hidden", "lg:relative lg:pt-0")}
              id="navbar-default"
            >
              <ul className="pl-8 py-4 lg:pl-0 lg:py-0 bg-[#1A1C22] font-medium w-full">
                {userId && !isSetupProfilePage && (
                  <>
                    <div className="flex flex-col gap-y-8 lg:justify-center pb-8">
                      {[...filteredNavBarList].splice(0, 4).map((item, index) => (
                        <li key={"menu-" + index}>
                          <NavLink
                            to={item.link}
                            className="btn-tertiary-navbar flex justify-between"
                            onClick={(e) => {
                              handleCloseMobileMenu(item);
                              handleLinkClick(e, item.link);
                            }}
                          >
                            {({ isActive }) => (
                              <>
                                <div className="flex justify-items-center">
                                  {renderIcon(item, isActive)}
                                  <span className={`pl-2 flex my-auto ${getTextStyle(item.title, isActive)}`}>{item.title}</span>
                                </div>
                                <p className="w-[0.25rem] h-5 bg-[#00CC9C] hidden"></p>
                              </>
                            )}
                          </NavLink>
                        </li>
                      ))}
                    </div>

                    <div className="h-[1px] w-[80%] bg-gray-800"></div>

                    <div className="flex flex-col gap-y-8 lg:justify-center py-8">
                      {[...filteredNavBarList].splice(4, 4).map((item, index) => (
                        <li key={"menu-" + (index + 4)}>
                          <NavLink
                            to={item.link}
                            className="btn-tertiary-navbar flex justify-between"
                            onClick={(e) => {
                              handleCloseMobileMenu(item);
                              handleLinkClick(e, item.link);
                            }}
                          >
                            {({ isActive }) => (
                              <>
                                <div className="flex justify-items-center">
                                  {renderIcon(item, isActive)}
                                  <span className={`pl-2 flex my-auto ${getTextStyle(item.title, isActive)}`}>{item.title}</span>
                                </div>
                                <p className="w-[0.25rem] h-5 bg-[#00CC9C] hidden"></p>
                              </>
                            )}
                          </NavLink>
                        </li>
                      ))}
                    </div>

                    <div className="h-[1px] w-[80%] bg-gray-800"></div>

                    <div className="flex flex-col gap-y-8 lg:justify-center pt-8">
                      {[...filteredNavBarList].splice(8).map((item, index) => (
                        <li key={"menu-" + (index + 8)}>
                          <NavLink
                            to={item.link}
                            className="btn-tertiary-navbar flex justify-between"
                            onClick={(e) => {
                              handleCloseMobileMenu(item);
                              handleLinkClick(e, item.link);
                            }}
                          >
                            {({ isActive }) => (
                              <>
                                <div className="flex justify-items-center">
                                  {renderIcon(item, isActive)}
                                  <span className={`pl-2 flex my-auto ${getTextStyle(item.title, isActive)}`}>{item.title}</span>
                                </div>
                                <p className="w-[0.25rem] h-5 bg-[#00CC9C] hidden"></p>
                              </>
                            )}
                          </NavLink>
                        </li>
                      ))}
                    </div>
                    <li className="lg:hidden">
                      <Logout />
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>

          <div className="hidden lg:block">
            <Logout />
          </div>
        </div>
      </nav>

      <ContactSupportSucessModal show={showSuccessDialog} handleClose={() => setShowSuccessDialog(false)} />
    </>
  );
};

export default NavBar;
