import React, { useContext } from "react";
import { IoLogOutOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "@/provider/AuthContext";
import { useWeb3Auth } from "@services/Web3AuthService"; // Updated import
import useStore from "@store/useStore";

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { reset } = useStore();
  const { web3auth } = useWeb3Auth(); // Use the hook to get the web3auth instance

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    try {
      if (web3auth.connected) {
        // Check if the user is connected before logging out
        await web3auth.logout();
      }
    } catch (error) {
      console.error("Web3Auth logout error:", error);
    }

    logout(); // Log out from your application
    reset(); // reset store data
    localStorage.removeItem("authToken");
    localStorage.removeItem("web3AuthToken");
    navigate("/"); // Redirect to the home page
    window.location.reload(); //ensure a fresh state
  };

  return (
    // <NavLink to={"/"} onClick={handleLogout} className="text-[#FF1E1E] flex items-center text-lg mt-8">
    //   <IoLogOutOutline className="nav-icon" /> &nbsp; Logout
    // </NavLink>


    <NavLink
      to={"/"} onClick={handleLogout} className="text-[#FF1E1E] flex items-center text-lg mt-8"
    >
      {({ isActive }) => (
        <>
          <div className="flex justify-items-center">
            <IoLogOutOutline className="nav-icon" />
            <span className={`pl-2 flex my-auto text-sm `}>
              Logout
            </span>
          </div>
          <p className="w-[0.25rem] h-5 bg-[#00CC9C] hidden"></p>
        </>
      )}
    </NavLink>
  );
};

export default Logout;