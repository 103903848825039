import React, { useState } from "react";
import Button from "@/components/UI/Button";
import CustomCheckbox from "./custom-checkbox";
// import { tokenTransferPurchaseRequest } from "@/services/api";
import { GoArrowUpRight } from "react-icons/go";

interface InvestPageTwoProps {
  handlePrevPage: () => void;
  handleNextPage: (success: boolean) => void;
  projectId: string;
  investorId: string;
  tokenId: string;
  purchase: number;
}

const InvestPageTwo: React.FC<InvestPageTwoProps> = ({ handlePrevPage, handleNextPage, investorId, tokenId, purchase }) => {
  const [agreement, setAgreement] = useState(false);
  const [smartContractAgreement, setSmartContractAgreement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = () => {
    setAgreement((prevState) => !prevState);
  };

  const handleSmartContractCheckboxChange = () => {
    setSmartContractAgreement((prevState) => !prevState);
  };

  const handlePurchaseRequest = async () => {
    setIsLoading(true);
    try {
      // await tokenTransferPurchaseRequest(investorId, tokenId, { tokenAmount: purchase });
      handleNextPage(true);
    } catch (error) {
      console.error("Error in purchase request:", error);
      handleNextPage(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h5 className="my-1 font-bold">Purchase Agreement</h5>
      <div
        className="overflow-y-auto max-h-40 border border-monochrome-40 p-2 my-4 rounded-sm bg-monochrome-60"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "black #A9A9A9",
        }}
      >
        <p>
          Terms and Conditions for Token Purchase Introduction These Terms and Conditions (“Terms”) govern the purchase of tokens (“Tokens”) from [Company Name]
          (“Company”). By purchasing Tokens, you agree to be bound by these Terms. Eligibility You must be at least 18 years old and have the legal capacity to
          enter into a contract to purchase Tokens. Token Purchase The purchase of Tokens is final and non-refundable. Tokens are purchased using [Accepted
          Payment Methods] and will be delivered to your provided wallet address. Use of Tokens Tokens may be used solely for accessing and utilizing the
          services provided by the Company. Tokens do not represent any ownership or equity interest in the Company. Risks The purchase of Tokens involves
          significant risk. You acknowledge that the value of Tokens may fluctuate and that you may lose all or part of your investment. The Company does not
          guarantee any specific value or return on investment for Tokens. Compliance You agree to comply with all applicable laws and regulations regarding the
          purchase and use of Tokens. The Company reserves the right to refuse or cancel any Token purchase if it believes the transaction violates any laws or
          regulations. Limitation of Liability The Company shall not be liable for any direct, indirect, incidental, or consequential damages arising from the
          purchase or use of Tokens. The Company’s total liability shall not exceed the amount you paid for the Tokens. Amendments The Company reserves the
          right to amend these Terms at any time. Any changes will be effective immediately upon posting on the Company’s website. Governing Law These Terms
          shall be governed by and construed in accordance with the laws of [Jurisdiction]. Contact Information For any questions or concerns regarding these
          Terms, please contact us at [Contact Information].
        </p>
      </div>
      <CustomCheckbox
        checked={agreement}
        onChange={handleCheckboxChange}
        label="I have read and agree to the Purchase Agreement and Terms & Conditions"
      />

      {/* New Section for Smart Contract Registry */}
      <h5 className="my-1 font-bold pt-6 text-sm">Smart Contract Registry</h5>
      <div className="my-4">
        <a href="https://docs.google.com/document/d/1Q5kc-o7H_G8lEP4_uNNaMUkAnVxQiru5/edit?usp=sharing&ouid=114294399236093119021&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="text-primary-500 underline">
          <span className="flex items-center">
          View Smart Contract Registry Legal Terms & Conditions <GoArrowUpRight />
          </span>
          
        </a>
      </div>
      <CustomCheckbox
        checked={smartContractAgreement}
        onChange={handleSmartContractCheckboxChange}
        label="I have read and agree to the Smart Registry Legal Terms & Conditions"
      />

      <div className="flex justify-end gap-4 mt-4">
        <Button onClick={handlePrevPage} classNames="btn !bg-monochrome-60 !text-white" disabled={isLoading}>
          Back
        </Button>
        <Button
          onClick={handlePurchaseRequest}
          classNames="btn btn-primary w-max"
          disabled={!agreement || !smartContractAgreement || isLoading}
        >
          {isLoading ? "Loading..." : "Request Token Purchase"}
        </Button>
      </div>
    </div>
  );
};

export default InvestPageTwo;
