//Vijays Work
// import { IcCopy } from "@/assets";
// import Button from "@/components/UI/Button";
// import useAuth from "@/hooks/useAuth";
// import { fetchUser } from "@/services/api";
// import { useQuery } from "@tanstack/react-query";
// import React from "react";
// import toast from "react-hot-toast";

// const Info = ({ setSelectedTab }: any) => {
//   const { userId } = useAuth();
//   const {
//     data: userData,
//     isLoading: isLoadingUser,
//     refetch: refetchUserData,
//   } = useQuery({
//     queryKey: ["getUser", userId],
//     queryFn: () => fetchUser(userId),
//     enabled: !!userId,
//     select: (data) => data.data,
//   });

//   const handleOnCopy = () => {
//     navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code);
//     toast.success("Referral code copied successfully");
//   };

//   const referralLink = process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code;

//   return (
//     <div className="bg-monochrome-100 p-2 sm:p-4 flex justify-center items-center rounded-lg mx-2 mt-4 sm:mx-4 mb-0 min-h-[calc(100vh-350px)] lg:min-h-[calc(100vh-262px)]">
//       <div className="w-full max-w-3xl flex flex-col items-center px-2 sm:px-4">
//         <p className="text-center text-green-500 text-sm sm:text-base px-2 mb-4">
//           {`Welcome, ${userData?.firstName} ${userData?.lastName}, to the PNMO campaigns! Here, you can earn XP by completing quests. In the future, your XP will be converted into PNMO tokens.

// But that’s not all! You can also earn bonus XP by inviting your friends to join Penomo using your referral link. Start your journey now and maximize your rewards!`}
//         </p>

//         <div className="flex items-center bg-monochrome-40 sm:w-[60%] max-w-md mx-auto rounded-xl border border-green-400">
//           <div className="flex-1 overflow-hidden px-3 py-2">
//             <p className="text-xs sm:text-sm truncate">{referralLink}</p>
//           </div>
//           <button
//             className="cursor-pointer flex-shrink-0 p-2 hover:bg-monochrome-50 rounded-full transition-colors mr-1"
//             onClick={handleOnCopy}
//             aria-label="Copy referral link"
//           >
//             <IcCopy />
//           </button>
//         </div>

//         <div className="flex gap-4">
//           <Button secondary classNames="mt-4 rounded-lg text-black" onClick={() => setSelectedTab(1)}>
//             Go to Quests
//           </Button>
//           <Button secondary classNames="mt-4 rounded-lg text-black" onClick={() => setSelectedTab(2)}>
//             Go to Leaderboard
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Info;

import { IcCopy } from "@/assets";
import Button from "@/components/UI/Button";
import useAuth from "@/hooks/useAuth";
import { fetchUser } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";

const Info = ({ setSelectedTab }: any) => {
  const { userId } = useAuth();
  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserData,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const handleOnCopy = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code);
    toast.success("Referral code copied successfully");
  };

  const referralLink = process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code;

  return (
    <div className="bg-monochrome-100 p-2 sm:p-4 flex justify-center items-center rounded-lg mx-2 sm:mx-4 mb-0 min-h-[calc(100vh-350px)] lg:min-h-[calc(100vh-262px)]">
      <div className="w-full max-w-2xl flex flex-col items-center px-2 sm:px-4">
        <p className="text-center text-green-500 text-sm sm:text-base px-2 mb-4">
          Earn XP* by referring penomo to your friends with the below link.
          <br />
          Your XP will be credited after the referee has successfully purchased the $PNMO tokens.
        </p>

        <div className="flex items-center bg-monochrome-40 w-full max-w-md mx-auto rounded-xl border border-green-400">
          <div className="flex-1 overflow-hidden px-3 py-2">
            <p className="text-xs sm:text-sm truncate">{referralLink}</p>
          </div>
          <button
            className="cursor-pointer flex-shrink-0 p-2 hover:bg-monochrome-50 rounded-full transition-colors mr-1"
            onClick={handleOnCopy}
            aria-label="Copy referral link"
          >
            <IcCopy />
          </button>
        </div>
        <p className="text-center text-green-500 text-sm sm:text-base px-2 mb-4 mt-4">*Your XP will be converted to $PNMO tokens.</p>
      </div>
    </div>
  );
};

export default Info;
