import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

// import { getTokenPurchaseRequests, getTokenHoldings } from "@/services/api";
import { useNetwork } from "@/provider/NetworkContext";
import useStore from "@/store/useStore";
import { IcSearch } from "@/assets";
import { classNames } from "@/utils";

import PortfolioHoldings from "./PortfolioHoldings";
import PortfolioRequests from "./PortfolioRequests";
import { useSearchParams } from "react-router-dom";
import { IPortfolioHoldings } from "@/types";

const sections = [
  {
    key: "holdings",
    label: "Holdings",
  },
  {
    key: "requests",
    label: "Purchase Requests",
  },
];

const getTokenAmount = (data: any): number => {
  return formatWeiToEther(data);
};

const formatWeiToEther = (weiValue: string): number => {
  const etherValue = +weiValue / Math.pow(10, 18); // Divide by 10^18 to convert wei to ether
  return etherValue; // Format to 4 decimal places (adjust as needed)
};

const PortofolioTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [activeSection, setActiveSection] = React.useState<string>(tab == "purchaseRequest" ? "requests" : "holdings");
  const { investorData } = useStore();
  const investorId = investorData._id;
  const { selectedNetwork } = useNetwork();
  const [searchHoldings, setSearchHoldings] = React.useState<any>(""); // Single search term for both token name and token amount
  const [searchPurchaseRequest, setSearchPurchaseRequest] = React.useState<any>(""); // Single search term for both token name and token amount

  const {
    data: requestData = [],
    isLoading: isRequestLoading,
    refetch: refecthRequest,
  } = useQuery({
    queryKey: ["getTokenPurchaseData", investorId],
    // queryFn: () => getTokenPurchaseRequests(investorId),
    enabled: !!investorId,
    select: (res) => res.data,
  });

  const { data: holdingData = [], isLoading: isHoldingLoading } = useQuery({
    queryKey: ["getTokenHoldings", investorId],
    // queryFn: () => getTokenHoldings(investorId, selectedNetwork),
    enabled: !!investorId && activeSection === "holdings",
    select: (res) => res.data,
  });
  // console.log(holdingData);

  useEffect(() => {
    if (activeSection === "requests") {
      refecthRequest();
    }
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");

    if (tab === "requests") {
      setActiveSection("requests");
    }
  }, []);

  const filteredPurchaseRequestData = React.useMemo(() => {
    return requestData.filter((item: any) => {
      const tokenName = item.projectId.name.toLowerCase();
      const tokenAmount = (item.tokenAmount * item.projectId.tokenPrice).toString();
      const search = searchPurchaseRequest?.toLowerCase();

      // Check if search term is numeric (could be a partial number for token amount search)
      const isSearchNumeric = !isNaN(searchPurchaseRequest);

      // If search term is numeric, compare with token amount
      if (isSearchNumeric) {
        return tokenAmount.includes(search);
      }

      // Otherwise, compare with token name
      return tokenName.includes(search);
    });
  }, [searchPurchaseRequest, requestData]);

  const filteredHoldingData = React.useMemo(() => {
    return holdingData.filter((item: any) => {
      const tokenName = item.projectName?.toLowerCase();
      const tokenAmount = getTokenAmount(item.tokenBalance) * 500;
      const tokenAmountString = tokenAmount.toString();

      const search = searchHoldings?.toLowerCase();

      // Check if search term is numeric (could be a partial number for token amount search)
      const isSearchNumeric = !isNaN(searchHoldings);

      // If search term is numeric, compare with token amount
      if (isSearchNumeric) {
        return tokenAmountString.includes(search);
      }

      // Otherwise, compare with token name
      return tokenName?.includes(search);
    });
  }, [searchHoldings, holdingData]);

  return (
    <div className="flex flex-col bg-monochrome-100 p-[16px] rounded-[8px] h-full min-h-screen">
      <div className=" flex justify-between w-full items-center mb-[8px]">
        <h3 className=" text-[15px] font-medium">RWA Token Holdings</h3>
        <div className="relative flex items-center w-full sm:w-[200px] bg-monochrome-80 border-monochrome-60 rounded-[8px] h-[33px]">
          <input
            type="text"
            placeholder="Search..."
            className="!w-full !sm:w-[100px] !md:w-[200px] !h-[33px] !text-[12px] !m-0 !rounded-[8px] !border-none !min-h-min"
            // value={searchTerm}
            onChange={(e) => (activeSection === "holdings" ? setSearchHoldings(e.target.value) : setSearchPurchaseRequest(e.target.value))}
          />
          <div className="absolute right-2 text-monochrome-20">
            <IcSearch />
          </div>
        </div>
      </div>
      <div className=" p-1 rounded-xl bg-monochrome-80 flex gap-[10px] w-fit mb-2" style={{ boxShadow: "inset 0 0 10px rgba(255, 255, 255, 0.03)" }}>
        {sections.map((section) => (
          <button
            key={section.key}
            className={classNames(" text-xs font-normal leading-[18px] py-2 px-[18px] rounded-lg cursor-pointer flex items-center gap-[10px]", {
              "text-white bg-monochrome-100": section.key === activeSection,
              "text-monochrome-20 hover:bg-monochrome-80": section.key !== activeSection,
            })}
            onClick={() => setActiveSection(section.key)}
          >
            {section.label}
            {section.key === "requests" && !isRequestLoading && (
              <div
                className={` ${
                  filteredPurchaseRequestData.length == 0 ? "bg-monochrome-40" : "bg-danger-100"
                } flex justify-center items-center w-4 h-4 px-2 rounded-full`}
              >
                <span className=" text-white text-[10px] font-normal leading-[15px]">{filteredPurchaseRequestData.length}</span>
              </div>
            )}
          </button>
        ))}
      </div>

      {activeSection === "holdings" && <PortfolioHoldings data={filteredHoldingData} isLoading={isHoldingLoading} />}
      {activeSection === "requests" && <PortfolioRequests data={filteredPurchaseRequestData} isLoading={isRequestLoading} refecthRequest={refecthRequest} />}
    </div>
  );
};

export default PortofolioTable;
