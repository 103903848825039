import SummaryCard from "../components/Page/Portfolio/PortfolioSummaryCard";
import PortofolioTable from "../components/Page/Portfolio/PortfolioTable";
import { IcWallet, IcHandsCoin } from "../assets";
import TokenSale from "./TokenPurchase";
import useStore from "@/store/useStore";
import { useNetwork } from "@/provider/NetworkContext";
import { useQuery } from "@tanstack/react-query";
import {
  fetchInvestor,
  fetchUser,
  // getTokenHoldings
} from "@/services/api";
import { useEffect, useState } from "react";
import { IPortfolioHoldings } from "@/types";
import useAuth from "@/hooks/useAuth";

const formatWeiToEther = (weiValue: string): number => {
  const etherValue = +weiValue / Math.pow(10, 18); // Divide by 10^18 to convert wei to ether
  return etherValue; // Format to 4 decimal places (adjust as needed)
};

const getTokenAmount = (data: IPortfolioHoldings): number => {
  return formatWeiToEther(data as any);
};

const PortfolioPage = () => {
  const { userId } = useAuth();
  const [totalInvested, setTotalInvested] = useState(0);
  const { investorData } = useStore();
  const investorId = investorData._id;
  const { selectedNetwork } = useNetwork();
  // eslint-disable-next-line
  let holdingData = [];
  // const { data: holdingData = [], isLoading: isHoldingLoading } = useQuery({
  //   queryKey: ["getTokenHoldings", investorId],
  //   queryFn: () => getTokenHoldings(investorId, selectedNetwork),
  //   enabled: !!investorId,
  //   select: (res) => res.data,
  // });

  const { data: investorData2, isLoading: isLoadingInvestor } = useQuery({
    queryKey: ["getInvestor", investorId],
    queryFn: () => fetchInvestor(investorId),
    enabled: !!investorId,
    select: (data) => data.data,
  });

  useEffect(() => {
    const total = holdingData.reduce((acc, curr) => acc + getTokenAmount(curr.tokenBalance) * 500, 0);
    setTotalInvested(total);
  }, [holdingData]);

  return (
    // <div>
    //   <TokenSale />
    // </div>
    <div>
      <div className=" grid sm:grid-cols-2 grid-cols-1 sm:space-x-[16px] sm:space-y-0 space-y-[16px] mb-[16px]">
        <SummaryCard title="Total Invested" amount={totalInvested} Icon={IcHandsCoin} />
        <SummaryCard title="Total Yield" amount={investorData2?.walletYields ?? 0} Icon={IcWallet} />
      </div>

      <PortofolioTable />
    </div>
  );
};

export default PortfolioPage;
