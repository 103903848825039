const TokenSale = () => {
  return (
    <div className="transactions-page">
      <h1 className="page-header">Token Sale</h1>
      <div className="page-container md:!mt-10">
        <iframe src="https://tokentool.bitbond.com/tokensale/0xd6dA948f68B2e4a8CfCfe0C00dDFb67B0210ea53?chainId=56" width="100%" height="1000px" className="absolute inset-0 w-full h-full" name="tokensale"></iframe>
      </div>
    </div>
  );
};

export default TokenSale;
