import React, { useState, useEffect, useContext } from "react";
import { Modal } from "flowbite-react";
import Web3 from "web3";
import { AuthContext } from "@/provider/AuthContext";
import carbon from "@/assets/carbon_energy-renewable.png";
import IcCross from "@/assets/ic_cross";
import Button from "@/components/UI/Button";
// import { completeTransfer } from "@/services/api";
import TransferResultModal from "./TransferResultModal";
import USDC_ABI from "../../usdc_abi.json";
import toast from "react-hot-toast";
import { GoArrowUpRight } from "react-icons/go";

const USDC_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"; // USDC contract on Polygon Mainnet

interface PaymentTokenModalProps {
  show: boolean;
  handleModal: () => void;
  projectData: any;
  tokenAmount: number;
  purchaseId: string;
  companyWalletId: string;
  refetch: any;
}

const PaymentTokenModal: React.FC<PaymentTokenModalProps> = ({
  show,
  handleModal,
  projectData,
  tokenAmount,
  purchaseId,
  refetch,
  companyWalletId
}) => {
  const [transferType, setTransferType] = useState<'bank' | 'crypto'>('bank');
  const [showResultModal, setShowResultModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [usdcBalance, setUsdcBalance] = useState<string>("0.0");
  const [isTransferring, setIsTransferring] = useState<boolean>(false);
  const { provider } = useContext(AuthContext);

  const amountToTransfer = tokenAmount * 500;

  useEffect(() => {
    if (provider && transferType === 'crypto') {
      const web3 = new Web3(provider);
      const fetchUSDCBalance = async () => {
        try {
          const accounts = await web3.eth.getAccounts();
          if (accounts.length === 0) return;

          const account = accounts[0];
          const usdcContract = new web3.eth.Contract(USDC_ABI as any, USDC_ADDRESS);
          const usdcBalance = await usdcContract.methods.balanceOf(account).call();
          const formattedUSDCBalance = web3.utils.fromWei(usdcBalance, "mwei"); // USDC uses 6 decimals
          setUsdcBalance(formattedUSDCBalance);
        } catch (error) {
          console.error("Error fetching USDC balance:", error);
        }
      };

      fetchUSDCBalance();
    }
  }, [provider, transferType]);

  const handleClose = () => {
    handleModal();
  };

  const handleCompletePayment = async () => {

    if (transferType === 'crypto' && parseFloat(usdcBalance) < amountToTransfer) {
      toast.error("Insufficient balance to complete the transaction.");
      return;
    }

    if (transferType === 'bank') {
      await executeBankTransfer();
    } else {
      await executeCryptoTransfer();
    }
  };

  const executeBankTransfer = async () => {
    try {
      const reqBody = {
        transferType:"BankTransfer",
        iban: projectData?.companyId?.bankIban || "",
        accountHolderName: projectData?.companyId?.bankAccountHolderName || "",
        bankName: projectData?.companyId?.bankName || "",
        accountNumber: projectData?.companyId?.bankAccountNumber || "",
        SwiftCode: projectData?.companyId?.bankSwiftBicCode || ""
      };

      // const response = await completeTransfer(purchaseId, reqBody);
      const response = {};
      if (response.code === 200) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
      setShowResultModal(true);
      handleClose();
      await refetch();
    } catch (error) {
      console.error("Error completing bank payment:", error);
      setIsSuccess(false);
      setShowResultModal(true);
      handleClose();
      await refetch();
    }
  };

  const executeCryptoTransfer = async () => {
    if (!provider) return;
    setIsTransferring(true);
    const web3 = new Web3(provider);

    try {
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      if (amountToTransfer > parseFloat(usdcBalance)) {
        throw new Error("Insufficient balance");
      }

      const amountInMwei = web3.utils.toWei(amountToTransfer.toString(), "mwei");
      const usdcContract = new web3.eth.Contract(USDC_ABI as any, USDC_ADDRESS);

      const estimatedGas = await usdcContract.methods
        .transfer(companyWalletId, amountInMwei)
        .estimateGas({ from: account });

      const txReceipt = await usdcContract.methods
        .transfer(companyWalletId, amountInMwei)
        .send({
          from: account,
          gas: estimatedGas,
        });

      const reqBody={
        transferType:'cryptoTransfer',
        transactionHash:txReceipt.transactionHash || '',
        companyWalletId:companyWalletId || ''
      }

      // const response = await completeTransfer(purchaseId, reqBody);
      const response={};
      if (response.code === 200) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }

      setIsSuccess(true);
      setShowResultModal(true);
      handleClose();
      await refetch();
    } catch (error) {
      console.error("Error completing crypto payment:", error);
      setIsSuccess(false);
      setShowResultModal(true);
      handleClose();
      await refetch();
    } finally {
      setIsTransferring(false);
    }
  };

  return (
    <>
      <Modal show={show} onClose={handleClose} position="center" size="xl">
        <Modal.Body className="p-4 bg-monochrome-100">
          <div className="flex justify-between items-center pb-5">
            <h1 className="mb-0">Complete Payment</h1>
            <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={handleClose}>
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />

          {/* Tab Selection */}
          <div className="flex mb-4 mt-4">
            <Button
              classNames={`w-1/2 ${transferType === 'bank' ? 'bg-primary-100 text-black' : 'bg-monochrome-60'}`}
              onClick={() => setTransferType('bank')}
            >
              Bank Transfer
            </Button>
            <Button
              classNames={`w-1/2 ${transferType === 'crypto' ? 'bg-primary-100 text-black' : 'bg-monochrome-60'}`}
              onClick={() => setTransferType('crypto')}
            >
              Crypto Transfer
            </Button>
          </div>

          <div className="my-4 space-y-4">
            {/* Token Information */}
            <h5 className="my-1 font-bold">Token</h5>
            <div className="w-full flex justify-between rounded-md border border-monochrome-40 p-2">
              <div className="flex gap-2 items-center">
                <div className="flex justify-center items-center bg-monochrome-60 p-2 rounded-full">
                  <img style={{ width: "22px", height: "22px" }} src={carbon} alt="" />
                </div>
                <div className="flex flex-col">
                  <p>{projectData?.assetIds[0]?.assetType || ""}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="py-1 px-2 h-max w-max flex justify-center items-center rounded-xl bg-monochrome-60">
                  <p>$500/token</p>
                </div>
                <div className="py-1 px-2 h-max w-max flex justify-center items-center rounded-xl bg-[#00CC9C20]">
                  <p>APY: 10%</p>
                </div>
              </div>
            </div>

            {/* Purchase Details */}
            <h5 className="mb-2 mt-3 font-bold">Purchase Details</h5>
            <h6>Amount to Send</h6>
            <div className="w-full flex justify-between gap-5 items-center rounded-md border border-monochrome-40 bg-monochrome-100 p-2 overflow-hidden">
              <input
                type="text"
                className="w-max !text-2xl !font-bold !outline-none !hover:outline-none !bg-inherit !border-none"
                value={`$${amountToTransfer}`}
                readOnly
              />
            </div>

            {transferType === 'bank' ? (
              // Bank Details
              <>
                <h5 className="my-1 font-bold">Bank Details</h5>
                <div className="w-full p-4 space-y-2 items-center rounded-md border border-monochrome-40 bg-monochrome-100 overflow-hidden">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-monochrome-20">Account Holder Name</p>
                      <p className="text-white font-bold">{projectData?.companyId?.bankAccountHolderName || ""}</p>
                    </div>
                    <div className="text-right">
                      <p className="text-monochrome-20">Bank Name</p>
                      <p className="text-white font-bold">{projectData?.companyId?.bankName || ""}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-monochrome-20">Account Number</p>
                      <p className="text-white font-bold">{projectData?.companyId?.bankAccountNumber || ""}</p>
                    </div>
                    <div className="text-right">
                      <p className="text-monochrome-20">SWIFT/BIC Code</p>
                      <p className="text-white font-bold">{projectData?.companyId?.bankSwiftBicCode || ""}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-monochrome-20">IBAN</p>
                      <p className="text-white font-bold">{projectData?.companyId?.bankIban || ""}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              // Crypto Transfer Details
              <>
                <h5 className="my-1 font-bold">USDC Transfer</h5>
                <a href="" target="_blank" className="flex items-center gap-2 underline text-green-500">
                  <p>Top up USDC to your Wallet </p>
                  <GoArrowUpRight />
                </a>
                
                <div className="w-full p-4 space-y-2 items-center rounded-md border border-monochrome-40 bg-monochrome-100 overflow-hidden">
                  <div className="flex items-center justify-between">
                    <p className="text-sm">USDC Balance</p>
                    <div className="flex items-center gap-2 text-base">
                      <p className="text-white font-bold">
                        {usdcBalance}
                      </p> 
                      <img
                        src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035"
                        alt="currency icon"
                        className="flex w-4 h-4"
                      />
                    </div>
                  </div>
                  {/* <div>
                    <p className="text-monochrome-20">Amount to Transfer (USDC)</p>
                    <input
                      type="text"
                      className="w-full bg-transparent border border-monochrome-40 rounded p-2 mt-1"
                      value={`${amountToTransfer} USDC`}
                      readOnly
                    />
                  </div> */}
                  <div>
                    <p className="text-white mt-8">Destination Address</p>
                    <input
                      type="text"
                      className="w-full bg-transparent border border-monochrome-40 rounded p-2 mt-1"
                      value={companyWalletId}
                      readOnly
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <Button
            classNames="btn btn-primary w-full"
            onClick={handleCompletePayment}
            disabled={transferType === 'crypto' && (parseFloat(usdcBalance) < amountToTransfer || isTransferring)}
          >
            {isTransferring ? "Processing..." : "Complete Payment"}
          </Button>
        </Modal.Body>
      </Modal>

      <TransferResultModal
        show={showResultModal}
        onClose={() => setShowResultModal(false)}
        success={isSuccess}
      />
    </>
  );
};

export default PaymentTokenModal;