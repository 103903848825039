import React from "react";
import Modal from "./Modal";
import { IcWarning } from "@/assets";
import { IPortfolioRequest } from "@/types";
// import { completeTransfer } from "@/services/api";

interface CompletePaymentAskModalProps {
  data: IPortfolioRequest;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CompletePaymentAskModal = ({ data, isOpen, onClose, onConfirm }: CompletePaymentAskModalProps) => {
  const remainingAmount = data.transactionDetails?.remaingAmountToPay || 0;
  const amountToTransfer = data.tokenAmount * 500;
  const amount = remainingAmount > 0 ? remainingAmount : amountToTransfer;

  const completePayment = async () => {
    try {
      const reqBody = {
        transferType:"BankTransfer",
        iban: data?.projectId?.companyId?.bankIban || "",
        accountHolderName: data?.projectId?.companyId?.bankAccountHolderName || "",
        bankName: data?.projectId?.companyId?.bankName || "",
        accountNumber: data?.projectId?.companyId?.bankAccountNumber || "",
        SwiftCode: data?.projectId?.companyId?.bankSwiftBicCode || ""
      };
      // const response = await completeTransfer(data._id, reqBody);
      const response=null;
      if (response.code === 200) {
        // setIsSuccess(true);
        onConfirm();
      } else {
        // setIsSuccess(false);
      }
  
      
    } catch (error) {
      console.error("Error completing payment:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Modal isOpen={isOpen} title="Complete Payment" onClose={() => onClose()}>
      <div className="flex flex-col">
        <div className="flex justify-center mb-4">
          <IcWarning className="text-monochrome-20" />
        </div>
        <p className="text-white font-medium text-sm leading-[21px] text-center mb-4">Are you sure you have completed the below transfer?</p>

        <p className="text-[11px] font-normal text-white mb-2">Payment Amount</p>
        <div className="flex items-center justify-between px-3 py-2 border-[0.5px] border-monochrome-40 rounded-lg mb-4">
          <p className="text-white text-[26px] font-medium leading-[39px]">${amount.toLocaleString()} USD</p>
          <p className="text-monochrome-20 text-xs font-normal leading-[18px]">{data.tokenAmount} tokens</p>
        </div>

        <div className="w-full flex gap-4">
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={() => onClose()}
          >
            No, Go Back
          </button>
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={completePayment}
          >
            Yes.
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CompletePaymentAskModal;
