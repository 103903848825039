// import React from 'react';
// import IcBuy from "@/assets/ic_buy";
// import IcCross from "@/assets/ic_cross";
// import IcPending from "@/assets/ic_pending";
// import IcSell from "@/assets/ic_sell";
// import { IcDropdownChecked } from "@/assets/menu";
// import { ITransaciton } from "@/types/transactions";
// import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";

// dayjs.extend(customParseFormat);

// interface ITransactionItemProps {
//     item: ITransaciton;
//     onClick: () => void;
//     isSelected: boolean;
//     uniqueId: string;
//     currentUserId: string;
//     currentWalletAddress: string;
// }

// const TransactionItem: React.FC<ITransactionItemProps> = ({
//   item,
//   onClick,
//   isSelected,
//   uniqueId,
//   currentUserId,
//   currentWalletAddress
// }) => {
//   // Handle both standard and token transaction dates
//   const parsedDate = dayjs(item?.updatedAt || item?.createdAt);

//   // Check if it's a token transaction
//   const isTokenTransaction = item?.transactionType === 'token transaction';
//   const isOnChain = isTokenTransaction || item?.transactionMethod === "ON_CHAIN";

//   const isIncomingTransaction = () => {
//     if (isTokenTransaction) {
//       return (item?.to?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
//     } else if (isOnChain) {
//       return (item?.toAddress?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
//     } else {
//       return item?.toUserId?._id === currentUserId;
//     }
//   };

//   const isOutgoingTransaction = () => {
//     if (isTokenTransaction) {
//       return (item?.from?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
//     } else if (isOnChain) {
//       return (item?.fromAddress?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
//     } else {
//       return item?.fromUserId?._id === currentUserId;
//     }
//   };

//   const transactionType = isIncomingTransaction() ? "Buy" : "Sell";

//   // Handle amount display for both transaction types
//   const getDisplayAmount = () => {
//     if (isTokenTransaction) {
//       // Convert wei to ETH for token transactions
//       const amountInEth = parseFloat(item?.tokenAmount) / Math.pow(10, 18);
//       return amountInEth.toFixed(2);
//     }
//     return Math.abs(item?.paymentTokensTransferred).toFixed(2);
//   };

//   const transactionColor = isIncomingTransaction()
//     ? "text-green-500"
//     : isOutgoingTransaction()
//     ? "text-red-600"
//     : "text-gray-400";
//   const transactionSign = isIncomingTransaction() ? "+" : isOutgoingTransaction() ? "-" : "";

//   let statusIcon;
//   switch (isTokenTransaction ? "COMPLETED" : item?.status) {
//     case "FAILED":
//       statusIcon = <IcCross />;
//       break;
//     case "COMPLETED":
//       statusIcon = <IcDropdownChecked />;
//       break;
//     case "PENDING":
//       statusIcon = <IcPending />;
//       break;
//     default:
//       statusIcon = null;
//   }

//   let transactionName;
//   if (isTokenTransaction) {
//     transactionName = "Security Token Transfer";
//   } else {
//     switch (item?.transactionPurpose) {
//       case "SECURITY_TOKEN_PURCHASE":
//         transactionName = "Security Token Purchase";
//         break;
//       case "DIVIDEND_PAYMENT":
//         transactionName = "Dividend Payment";
//         break;
//       case "SECURITY_TOKEN_SALE":
//         transactionName = "Sell Token";
//         break;
//       case "SECURITY_TOKEN_TRANSFER":
//         transactionName = "Security Token Transfer";
//         break;
//       case "PAYMENT_TOKEN_TOP_UP":
//         transactionName = "Top Up";
//         break;
//       case "PAYMENT_TOKEN_WITHDRAW":
//         transactionName = "Withdraw";
//         break;
//       default:
//         transactionName = null;
//     }
//   }

//   return (
//     <div
//       className={`grid grid-cols-5 py-4 cursor-pointer border-b border-monochrome-40 ${
//         isSelected ? "bg-monochrome-40" : ""
//       }`}
//       onClick={onClick}
//       id={uniqueId}
//     >
//       <div className="flex items-center gap-2">
//         <div className="flex">
//           {transactionType === "Buy" ? <IcBuy /> : <IcSell />}
//           {statusIcon}
//         </div>
//         {transactionName}
//       </div>
//       <div className="text-end">{isTokenTransaction ? item?.tokenSymbol : item?.paymentTokenName || "USD"}</div>
//       <div className="text-end">{parsedDate.format("MMM D, YYYY")}</div>
//       <div className="text-end">{parsedDate.format("hh:mm A")}</div>
//       <div className={`text-end ${transactionColor}`}>
//         {transactionSign}
//         {getDisplayAmount()}
//       </div>
//     </div>
//   );
// };

// export default TransactionItem;

import React from "react";
import IcBuy from "@/assets/ic_buy";

interface PrnmoTransaction {
  allocation: number;
  timestamp: string;
  txHash: string;
  _id: string;
}

interface ITransactionItemProps {
  transaction: PrnmoTransaction;
}

const TransactionItem: React.FC<ITransactionItemProps> = ({ transaction }) => {
  const handleClick = () => {
    window.open(`https://bscscan.com/tx/${transaction.txHash}`, "_blank");
  };

  return (
    <div className="grid grid-cols-4 py-4 cursor-pointer border-b border-monochrome-40 hover:bg-monochrome-40" onClick={handleClick}>
      <div className="flex items-center gap-2">
        <div className="flex">
          <IcBuy />
        </div>
        Buy
      </div>
      <div className="text-start">PRNMO</div>
      <div className="text-start text-green-500">+{transaction.allocation}</div>
      <div className="text-start text-green-500">${((transaction?.allocation || 0) * 0.0075).toFixed(2)}</div>
    </div>
  );
};

export default TransactionItem;
