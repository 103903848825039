import Button from "../../UI/Button";
import Loader from "../../Loader";
import RawTokenListItem from "./RawTokenListItem";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useNetwork } from "@/provider/NetworkContext";
import useAuth from "@/hooks/useAuth";
// import { getTokenHoldings } from "@/services/api";
import { useEffect } from "react";

const RawTokenList = () => {
  const navigate = useNavigate();
  const { selectedNetwork } = useNetwork();
  const { investorId } = useAuth();
  let tokenHoldingsData;
  // const {
  //   data: tokenHoldingsData,
  //   isLoading,
  //   refetch,
  // } = useQuery({
  //   queryKey: ["getTokenHoldings", investorId],
  //   queryFn: () => getTokenHoldings(investorId, selectedNetwork),
  //   enabled: !!investorId,
  //   select: (res) => res.data,
  // });
  // console.log("Token Holdings Data Data: ", tokenHoldingsData);

  useEffect(() => {
    if (investorId && selectedNetwork) {
      // refetch(); // Refetch data whenever the network changes
    }
  }, [investorId, selectedNetwork, 
    // refetch
  ]);

  return (
    <div className="bg-monochrome-100 p-4 flex flex-column rounded-lg">
      <div className="flex justify-between items-center">
        <h5 className="font-medium text-monochrome-20">RWA Tokens</h5>
        <Button disabled classNames="text-green-500 justify-end pr-0 text-[0.75rem] font-normal" onClick={() => navigate("/portfolio")}>
          View All
        </Button>
      </div>
      <div className="overflow-x-auto sm:overflow-hidden">
        <div className="mt-4 w-[150%] sm:w-full">
          <div className="grid grid-cols-4 text-monochrome-20 pb-6 pt-2 border-b border-monochrome-40">
            <span className="text-[0.688rem] text-[#9E9E9E]">Name</span>
            <span className=" text-end text-[0.688rem] text-[#9E9E9E]">APY</span>
            <span className=" text-end text-[0.688rem] text-[#9E9E9E]">Amount</span>
            <span className=" text-end text-[0.688rem] text-[#9E9E9E]">Value</span>
          </div>
          {/* <div className="center min-h-40 text-[0.75rem]">RWA tokens upcoming, stay tuned.</div> */}
          {/* {isLoading && (
            <div className="center min-h-20">
              <Loader isLoading={true} />
            </div>
          )} */}
          {/* {tokenHoldingsData?.slice(0, 5).map((data: any, index: any) => (
            <RawTokenListItem key={index} item={data} />
          ))} */}
          {/* {!isLoading && tokenHoldingsData?.length == 0 &&  */}
          <div className="center min-h-40 text-[0.75rem] text-monochrome-20">No RWA Tokens Available.</div>
          {/* } */}
        </div>
      </div>
    </div>
  );
};

export default RawTokenList;
