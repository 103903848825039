import React from "react";
import Modal from "./Modal";
import { IcWarning } from "@/assets";
import { IPortfolioRequest } from "@/types";
// import { requestRefund } from "@/services/api";

interface RequestRefundAskModalProps {
  data: IPortfolioRequest;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const RequestRefundAskModal = ({ data, isOpen, onClose, onConfirm }: RequestRefundAskModalProps) => {
  const amountToRefund = data.transactionDetails?.amountTransferredByInvestor || 0;

  const refundRequest = async () => {
    try {
      // const response = await requestRefund(data._id);
      const response = {};
      if (response.code === 200) {
        // setIsSuccess(true);
        onConfirm();
      } else {
        // setIsSuccess(false);
      }
  
      
    } catch (error) {
      console.error("Error completing payment:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Modal isOpen={isOpen} title="Request Refund" onClose={() => onClose()}>
      <div className="flex flex-col">
        <div className="flex justify-center mb-4">
          <IcWarning className="text-monochrome-20" />
        </div>
        <p className="text-white font-medium text-sm leading-[21px] text-center mb-4">Are you sure want to request refund?</p>

        <p className="text-[11px] font-normal text-white mb-2">Payment Amount</p>
        <div className="flex items-center justify-between px-3 py-2 border-[0.5px] border-monochrome-40 rounded-lg mb-4">
          <p className="text-monochrome-20 text-xs font-normal leading-[18px]">Refund Amount</p>
          <p className="text-white text-[18px] font-medium leading-[27px]">${amountToRefund.toLocaleString()} USD</p>
        </div>

        <div className="w-full flex gap-4">
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={() => onClose()}
          >
            No, Go Back
          </button>
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={refundRequest}
          >
            Yes, Request Refund
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RequestRefundAskModal;
