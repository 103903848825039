// import { fetchProjectById } from '@/services/api';
import InvestmentCard from './MarketplaceCard/InvestmentCard'
import Tabs from './Tabs/Tabs'
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export default function MarketplaceDetails() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId') || "";

  // const { data: projectData = { data: {} }, isLoading: isLoadingUser } = useQuery({
  //   queryKey: ["getProject", projectId],
  //   queryFn: () => fetchProjectById(projectId),
  //   enabled: !!projectId,
  // });
  const projectData={};

  return (
    <div className='w-full'>
      <InvestmentCard projectData={projectData.data} />
      <Tabs projectData={projectData.data}/>
    </div>
  )
}
