import { ROUTES } from "@/constants";
import React from "react";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <footer
      className={`z-50 w-full rounded-[8px] ${
        pathname == ROUTES.APP_ROOT || pathname == ROUTES.PROFILE_SETUP ? "" : "w-[calc(100%-25px)] xl:w-[calc(100%-30px)] mx-auto"
      } bg-monochrome-100 text-white text-center text-sm py-5 `}
    >
      © 2025 Penomo Ltd.
    </footer>
    // fixed bottom-0 right-0 sm:right-3 lg:right-2 xl:right-4
  );
};

export default Footer;
