import { classNames } from "@/utils";

const LogoLoader = ({
  isLoading = false,
  gifStyle,
}: {
  isLoading?: boolean;
  gifStyle?: React.CSSProperties;
}) => {
  return (
    <div
      className={classNames(
        "fixed inset-0 flex items-center justify-center",
        {
          hidden: !isLoading,
        }
      )}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Faded background
        zIndex: 50, // Ensure it stays on top
        width: "100%", // Full width
        height: "100%", // Full height
      }}
    >
      <img
        src={'/assets/logo_pulsing_fixed.svg'}
        alt="Loading..."
        className="p-3 rounded-md bg-[#282C35] animate-zoom"
        style={{
          width: "100px",  // Customize size
          height: "100px",
          ...gifStyle,
        }}
      />

    </div>
  );
};

export default LogoLoader;
